<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
      <dark-Toggler />
      <b-link class="navbar-brand" to="/">
        <span class="brand-logo">
          <b-img :src="appLogoImage" alt="logo" />
        </span>
      </b-link>
    </ul>
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>
    <div class="pr-2">
      <b-button variant="warning" @click="linked('add-dapp')"
        >ADD DAPP</b-button
      >
    </div>
    <div class="pr-2" v-if="!is_mobilesize && !check_is_login">
      <b-button
        size="sm"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @mouseover="mouseoverLogin()"
        @mouseleave="mouseoverLogin()"
        variant="success"
        @click="linked('login')"
        >LOGIN</b-button
      >
    </div>
    <div class="pr-5" v-if="!is_mobilesize && !check_is_login">
      <b-button
        size="sm"
        @mouseover="mouseover()"
        @mouseleave="mouseover()"
        :variant="mouseover_check ? 'success' : 'outline-success'"
        @click="linked('register')"
        >SIGNUP</b-button
      >
    </div>
    <b-navbar-nav
      class="nav align-items-center ml-auto"
      v-show="check_is_login"
    >
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ profileName }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            :text="helper.getNameText(user_name)"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="linked('profile')"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>
        <!-- <Translator /> -->

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
// import Locale from '@core/layouts/components/app-navbar/components/Locale.vue';
// import { Translator } from "vue-google-translate";
import { mixinList } from "@/mixins/mixinList";
import helper from "@/utils/helper";
import { $themeConfig } from "@themeConfig";
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BButton,
  BImg,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import Ripple from "vue-ripple-directive";
export default {
  mixins: [mixinList],
  data() {
    return {
      helper: helper,
      mouseover_check: false,
      mouseover_check_login: false,
      show_translater: "",
      countries: [
        {
          code: "en",
          name: "English",
          cname: "英语",
          ename: "English",
        },
        {
          code: "ru",
          name: "Русский",
          cname: "俄语",
          ename: "Russian",
        },
        {
          code: "zh-CN",
          name: "中国人",
          cname: "中文 (简体)",
          ename: "Chinese",
        },
        {
          code: "ja",
          name: "にほんご",
          cname: "日语",
          ename: "Japanese",
        },
        {
          code: "ko",
          name: "한국어",
          cname: "韩语",
          ename: "Korean",
        },
        {
          code: "es",
          name: "Español",
          cname: "西班牙语",
          ename: "Spanish",
        },
        {
          code: "ar",
          name: "العربية",
          cname: "阿拉伯语",
          ename: "Arabic",
        },
        {
          code: "pt",
          name: "Português",
          cname: "葡萄牙语",
          ename: "Portuguese",
        },
        {
          code: "fr",
          name: "Français",
          cname: "法语",
          ename: "French",
        },
      ],
    };
  },
  components: {
    BImg,
    BButton,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    DarkToggler,
  },
  setup() {
    // App Name
    const { appLogoImage, appName } = $themeConfig.app;
    return {
      appLogoImage,
      appName,
    };
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    profileName() {
      let userData = JSON.parse(localStorage.getItem("profile"));
      if (userData) {
        return userData.name;
      }
      return "";
    },
    user_name() {
      let userData = JSON.parse(localStorage.getItem("profile"));
      if (userData) {
        let name = userData.name || [];
        return name;
      }
      return null;
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("profile");
      location.reload();
    },
    linked: function (e) {
      this.$router.push({ path: "/" + e });
    },
    mouseover() {
      this.mouseover_check = !this.mouseover_check;
    },
    mouseoverLogin() {
      this.mouseover_check_login = !this.mouseover_check_login;
    },
  },
  mounted() {
    this.checkIsLogin();
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
.span_class {
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.45;
  /* color: #5e5873; */
}
img {
  border-style: none;
  width: 29px;
  margin-top: -20px;
  margin-left: 100px;
}
.height-change {
  height: 200px;
}
.header-text {
  color: #afbdc4;
  font-size: 13px;
}
.header-text-2 {
  font-size: 13px;
}
</style>
