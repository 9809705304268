export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Add DAPP',
    route: 'add-dapp',
    icon: 'TrendingUpIcon',
  },
  {
    title: 'Login',
    route: 'login',
    icon: 'LogInIcon',
  },
  {
    title: 'Register',
    route: 'register',
    icon: 'UserPlusIcon',
  }
]
